// https://stackoverflow.com/a/75614144

//
interface ResposenMessage {
  role: 'user' | 'assistant' | 'system';
  content: string;
}

//
export const openAiFetch = async (message: string, apiKey: string, model = '3'): Promise<ResposenMessage> => {
  if (apiKey === '') {
    return { role: 'system', content: 'error'}
  }
  //
  const DEFAULT_PARAMS = {
    // gpt-4はまだ使えない
    model: model === '3' ? "gpt-3.5-turbo" : "gpt-4",
    messages: [{ role: "user", content: message }],
    temperature: 0,
  }
  //
  const result = await fetch('https://api.openai.com/v1/chat/completions', {
      method: 'POST',
      headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + apiKey
      },
      body: JSON.stringify(DEFAULT_PARAMS)
  })

  // const resJson = await result.json()
  if (result.body) {
    const resJson = await fetchStream(result.body)
    //
    return resJson.choices[0].message as ResposenMessage
  }

  //
  return { role: 'system', content: 'error'}
}

//
const fetchStream = async (stream: ReadableStream<Uint8Array>) => {
  const reader = stream.getReader();

  //
  let max = 10000
  let resultText = ''
  while(max > 0) {
    const r = await reader.read()
    if (r.done) {
      break;
    }
    //
    resultText += new TextDecoder().decode(r.value)
    //
    max--
  }


  return JSON.parse(resultText)
}