import { FormEvent, useCallback, useEffect, useState } from "react"
import './css/App.css'
import { openAiFetch } from "./chatgpt/clientFetch";
import { clearInterval, setInterval } from 'worker-timers';

//
type ITEM = {
  id: number;
  date: number;
  dateLabel: string;
  title: string;
  memo: string;
  working: boolean;
  completed: boolean;
  generate?: string;
  type: string;
}

type STORAGE = { items: ITEM[] }

const STORAGE_KEY = 'sns_writer_app'
const STORAGE_APIKEY = 'sns_writer_key'

//
const App: React.FC = () => {

  //
  const loadItem = localStorage.getItem(STORAGE_KEY)
  const apiKeyStorage = localStorage.getItem(STORAGE_APIKEY)
  //
  const [ noticeMessage, setNoticeMessage ] = useState('')
  const [ isNotification, setIsNotification ] = useState(false)
  //
  const itemJsonStr = loadItem !== 'undefined' && loadItem !== null ? loadItem : '{"items":[]}'
  const [ list, setList ] = useState<ITEM[]>(JSON.parse(itemJsonStr).items)

  // --- --- ---
  // api-keyの登録
  //
  const keyCheck = useCallback(async (apikey: string) => {
    setNoticeMessage('check api-key...')
    const prompt = 'hello. Keep your reply short.'
    const checkRes = await openAiFetch(prompt, apikey)
    console.log(checkRes.content)
    if (checkRes.content !== 'error') {
      // API疎通確認
      setNoticeMessage('API-KEYの確認が取れました。')
      localStorage.setItem(STORAGE_APIKEY, apikey)
    }
  }, [])
  // api-key登録のハンドラ
  const onKeySubmit = useCallback((event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    //
    const form = event.target as HTMLFormElement
    if (form) {
      const fd = new FormData(form)
      const apiKey = fd.get('key')?.toString()
      if (apiKey) {
        form.reset()
        keyCheck(apiKey)
      }
    }
  }, [keyCheck])

  // ---
  // 新規予定の追加
  //
  const onSubmitHandle = useCallback((event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    //
    const form = event.target as HTMLFormElement
    if (form) {
      const fd = new FormData(form)
      const formDate = fd.get('date')
      const dateValue = formDate ? new Date(formDate.toString()) : new Date()
      //
      const value: ITEM = {
        id: Date.now(),
        date: dateValue.getTime(),
        dateLabel: dateValue.toLocaleString(),
        title: fd.get('title')?.toString() || '',
        memo: fd.get('memo')?.toString() || '',
        working: false,
        completed: false,
        type: fd.get('type')?.toString() || 'sns',
      }
      //
      form.reset()
      //
      setList([ value, ...list || []])
    }
  }, [list])

  // ---
  // chatgptにコメント作ってもらう
  const generateComment = useCallback(async (targetItem: ITEM) => {
    // ターゲットのステータスを更新する
    // ---
    // prompt
    let prompt = ''
    if (targetItem.type === 'sns') {
      prompt = targetItem.memo === '' ?
        `${targetItem.title}というタイトルでSNS用の文章を作成。ハッシュタグを文章の最後に追記。200文字以内で出力` :
        `「${targetItem.memo}」の言葉を参考に、${targetItem.title}というタイトルでSNS用の文章を作成。ハッシュタグを文章の最後に追記。200文字以内で出力`
    } else {
      prompt = targetItem.memo === '' ?
        `${targetItem.title}というタイトルで私を褒めて。200文字以内で出力` :
        `「${targetItem.memo}」の言葉を参考に、${targetItem.title}というタイトルで私を褒めて。200文字以内で出力`
    }
    // openAPI呼び出し
    const message = await openAiFetch(prompt, apiKeyStorage || '')
    //
    // console.log(memo + title)
    // console.log(message.content)
    //
    const updateItems = list.concat()
    const item = updateItems.find(item => item.id === targetItem.id)
    if (item) {
      item.generate = message.content
      item.completed = true
    }
    // 生成が完了したので通知
    handlePushNotif(targetItem.title, item?.generate || '')
    // 
    setList([ ...updateItems.concat() ])
  }, [apiKeyStorage, list])

  // ---
  // ローカルストレージに保存
  useEffect(() => {
    localStorage.setItem(STORAGE_KEY, JSON.stringify(list))
  }, [list])

  // ---
  // インターバル監視
  // interval
  useEffect(() => {
    let interval = -1
    const startInterval = () => {
      interval = setInterval(() => {
        //
        const nowTime = Date.now()
        //
        const updateList = list.concat()
        //
        updateList.filter(item => !item.working).forEach(checkItem => {
          if (checkItem.date < nowTime) {
            // 生成開始フラグ
            checkItem.working = true
            // generate ai comment
            generateComment(checkItem)
          }
        })
        //
        setList([ ...updateList.concat() ])
      }, 1000 * 20)
    }
    //
    startInterval()
    // 解除
    return () => {
      clearInterval(interval)
    }
  }, [generateComment, list])

  // --- --- ---
  // リストから完了したものを削除する
  const onRemoveComplete = (event: React.MouseEvent<HTMLButtonElement>) => {
    const newList = list.filter(item => !item.completed)
    //
    setList([ ...newList ])
  }

  // --- --- ---
  // プシュ通知の許可用
  //
  useEffect(() => {
    if ("Notification" in window) {
      // 通知が許可されていたら早期リターン
      const permission = Notification.permission;
      if (permission === "denied" || permission === "granted") {
        setIsNotification(true)
        return;
      }
      setIsNotification(false)
      // 通知の許可を求める
      Notification.requestPermission().then(() => new Notification("設定された時刻になったらお知らせします"));
    }
  }, []);

  const handlePushNotif = (title: string, body: string) => {
    if ("Notification" in window) {
      new Notification(`「${title}」の投稿時間です。\n${body}`);
    }
  };

  // --- --- ---
  //
  return (
    <div className="wrapper">
      <div className="formBlock">
        {/* 追加フォーム */}
        {apiKeyStorage &&
          <>
            <form onSubmit={onSubmitHandle} className="formPanel">
              <input type="datetime-local" name="date" />
              <input type="text" name="title" placeholder="タイトル" />
              <textarea name="memo" placeholder="メモ"></textarea>
              <div>
                <input type="radio" id="type-sns" name="type" value="sns" defaultChecked />
                <label htmlFor="type-sns">SNS</label>
                <input type="radio" id="type-praise" name="type" value="praise" />
                <label htmlFor="type-praise">ほめる</label>
              </div>
              <button>add</button>
            </form>
            <hr />
          </>
        }
        {/* APIキー */}
        {apiKeyStorage &&
          <span>API: {apiKeyStorage?.substring(0, 10)}···</span>
        }
        <form onSubmit={onKeySubmit} className="formPanel">
          <input type="text" name="key" placeholder="setup api key"></input>
          <button>set</button>
        </form>
        <span>Notification: {isNotification ? 'on' : 'off'}</span>
        {noticeMessage !== '' && 
          <div>
            <span>{noticeMessage}</span>
          </div>
        }
      </div>
      {/* 登録済みリスト */}
      <div className="list">
        {list && list.map((item) => {
          return (
            <div key={item.id} className="item" data-complete={item.completed}>
              <div className="post">
                <span>{item.dateLabel} ({item.completed ? '完了' : '予定'})</span>
                <span>{item.title}</span>
                {item.memo !== '' && <p>{item.memo}</p>}
              </div>
              {item.generate &&
                <div className="generate">
                  <p>{item.generate}</p>
                </div>
              }
            </div>
          )
        })}
        {/* */}
        {list?.length > 0 && 
          <div>
            <button type="button" onClick={onRemoveComplete}>完了を消去する</button>
          </div>
        }
      </div>
    </div>
  )
}

export default App
